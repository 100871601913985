import { useState, useEffect } from 'react';
import { Play, Pause, Clock, Loader2, Languages, Link as LinkIcon } from 'lucide-react';
import { LocationMessage } from './LocationMessage';
import YouTube from 'react-youtube';
import { loadVideoPreview } from '../utils/video/preview';
import { VideoPlayer } from './VideoPlayer';
import { SpotifyTrack } from './SpotifyTrack';
import { YouTubeEmbed } from './YouTubeEmbed';
import { PollView } from './PollView';
import type { PostContentProps } from '../types/post';

interface ExtendedPostContentProps extends PostContentProps {
  onPollVote: (optionIndex: number) => Promise<void>;
  isVoting: boolean;
  currentUserId?: string;
  gifUrl?: string;
  gifTitle?: string;
  boostConfig?: {
    externalLink?: string | null;
  };
  videoUrl?: string;
  drawingImage?: string;
}

const translations = {
  'pt-BR': {
    learnMore: 'Saiba Mais'
  },
  'en': {
    learnMore: 'Learn More'
  }
};

export function PostContent({
  content,
  imageUrl,
  videoUrl,
  audioUrl,
  location,
  spotifyTrack,
  youtubeVideo,
  poll,
  authorId,
  authorName,
  authorPhotoURL,
  isDarkMode,
  onPollVote,
  isVoting,
  currentUserId,
  gifUrl,
  gifTitle,
  boostConfig,
  drawingImage
}: ExtendedPostContentProps) {
  const [showTranslation, setShowTranslation] = useState(false);
  const [translatedContent, setTranslatedContent] = useState('');
  const [translating, setTranslating] = useState(false);
  const [shouldShowTranslate, setShouldShowTranslate] = useState(false);
  const [language, setLanguage] = useState('en');
  const userLanguage = navigator.language.split('-')[0] || 'en';

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const detectLanguage = async () => {
      if (!content.trim()) return;

      try {
        const detectUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${userLanguage}&dt=t&q=${encodeURIComponent(content)}`;
        const response = await fetch(detectUrl);
        const data = await response.json();
        const detectedLanguage = data[2];
        setShouldShowTranslate(detectedLanguage !== userLanguage);
      } catch (error) {
        console.error('Language detection error:', error);
      }
    };

    detectLanguage();
  }, [content, userLanguage]);

  const handleTranslate = async () => {
    if (!content.trim()) return;

    try {
      setTranslating(true);
      const googleTranslateUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${userLanguage}&dt=t&q=${encodeURIComponent(content)}`;
      const response = await fetch(googleTranslateUrl);
      const data = await response.json();
      const translatedText = data[0]
        .map((item: any[]) => item[0])
        .join(' ');
      
      setTranslatedContent(translatedText);
      setShowTranslation(true);
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      setTranslating(false);
    }
  };

  return (
    <>
      <p className={`whitespace-pre-wrap break-words mb-3 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {content}
      </p>
      
      {content && shouldShowTranslate && (
        <div className="mb-3">
          <button
            onClick={handleTranslate}
            disabled={translating}
            className={`inline-flex items-center space-x-1 px-2 py-1 rounded-full text-xs ${
              isDarkMode 
                ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            } transition-colors disabled:opacity-50`}
          >
            <Languages className="h-3 w-3" />
            <span>{translating ? 'Translating...' : 'Translate'}</span>
          </button>

          {showTranslation && translatedContent && (
            <div className={`mt-2 p-3 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-700/50 text-gray-300' 
                : 'bg-gray-50 text-gray-700'
            }`}>
              <p className="text-sm whitespace-pre-wrap break-words">{translatedContent}</p>
            </div>
          )}
        </div>
      )}
      
      {imageUrl && (
        <div className="mb-3">
          <div className="relative w-full aspect-square">
            <img
              src={imageUrl}
              alt="Post content"
              className="absolute inset-0 w-full h-full object-cover bg-black/5 rounded-lg"
              loading="lazy"
            />
          </div>
          {boostConfig?.externalLink && (
            <a
              href={boostConfig.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`mt-4 w-full flex items-center justify-center space-x-2 px-4 py-2 ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white rounded-lg transition-colors`}
            >
              <LinkIcon className="h-4 w-4" />
              <span>{t('learnMore')}</span>
            </a>
          )}
        </div>
      )}

      {drawingImage && (
        <div className="mb-3">
          <div className="relative w-full">
            <img
              src={drawingImage}
              alt="Drawing"
              className="w-full rounded-lg"
              loading="lazy"
            />
          </div>
        </div>
      )}

      {gifUrl && (
        <div className="relative w-full mb-3">
          <img
            src={gifUrl}
            alt={gifTitle || "GIF"}
            className="w-full rounded-lg"
            loading="lazy"
          />
        </div>
      )}

      {videoUrl && (
        <VideoPlayer url={videoUrl} />
      )}

      {audioUrl && (
        <div className={`w-full mt-2 mb-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-3`}>
          <audio
            src={audioUrl}
            className="w-full"
            controls
            preload="metadata"
          />
        </div>
      )}

      {spotifyTrack && (
        <SpotifyTrack track={spotifyTrack} />
      )}

      {youtubeVideo && (
        <YouTubeEmbed video={youtubeVideo} isDarkMode={isDarkMode} />
      )}

      {poll && (
        <PollView
          poll={poll}
          onVote={onPollVote}
          hasVoted={poll.options.some(option => option.votes.includes(currentUserId || ''))}
          isExpired={new Date() > new Date(poll.endTime)}
          isVoting={isVoting}
          isDarkMode={isDarkMode}
        />
      )}

      {location && (
        <div className="mb-3">
          <LocationMessage
            latitude={location.latitude}
            longitude={location.longitude}
            senderName={authorName}
            senderPhotoURL={authorPhotoURL || ''}
            isFeed={true}
          />
        </div>
      )}
    </>
  );
}