import { useState, useRef, useEffect } from 'react';
import { X, Pencil, Square, Circle, Eraser, Download, Undo, Redo, Check, Palette, Pipette, Edit3, Brush, Droplet, Feather, Layers } from 'lucide-react';

interface DrawingPanelProps {
  onSave: (imageData: string) => void;
  onClose: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Desenho',
    tools: {
      pencil: 'Lápis',
      rectangle: 'Retângulo',
      circle: 'Círculo',
      eraser: 'Borracha',
      eyedropper: 'Conta-gotas'
    },
    brushStyles: {
      normal: 'Normal',
      rough: 'Áspero',
      sketch: 'Esboço',
      calligraphy: 'Caligrafia',
      splatter: 'Respingo',
      dotted: 'Pontilhado',
      dashed: 'Tracejado'
    },
    actions: {
      undo: 'Desfazer',
      redo: 'Refazer',
      save: 'Salvar',
      cancel: 'Cancelar'
    },
    colors: 'Cores',
    colorPicker: 'Seletor de Cores',
    lineWidth: 'Espessura',
    brushStyle: 'Estilo de Pincel',
    transparency: 'Transparência'
  },
  'en': {
    title: 'Drawing',
    tools: {
      pencil: 'Pencil',
      rectangle: 'Rectangle',
      circle: 'Circle',
      eraser: 'Eraser',
      eyedropper: 'Eyedropper'
    },
    brushStyles: {
      normal: 'Normal',
      rough: 'Rough',
      sketch: 'Sketch',
      calligraphy: 'Calligraphy',
      splatter: 'Splatter',
      dotted: 'Dotted',
      dashed: 'Dashed'
    },
    actions: {
      undo: 'Undo',
      redo: 'Redo',
      save: 'Save',
      cancel: 'Cancel'
    },
    colors: 'Colors',
    colorPicker: 'Color Picker',
    lineWidth: 'Line Width',
    brushStyle: 'Brush Style',
    transparency: 'Transparency'
  }
};

type Tool = 'pencil' | 'rectangle' | 'circle' | 'eraser' | 'eyedropper';
type BrushStyle = 'normal' | 'rough' | 'sketch' | 'calligraphy' | 'splatter' | 'dotted' | 'dashed';

interface DrawingAction {
  type: 'path' | 'rectangle' | 'circle';
  points?: { x: number; y: number }[];
  start?: { x: number; y: number };
  end?: { x: number; y: number };
  color: string;
  lineWidth: number;
  brushStyle?: BrushStyle;
  opacity?: number;
}

export function DrawingPanel({ onSave, onClose, isDarkMode = false, language = 'en' }: DrawingPanelProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState<Tool>('pencil');
  const [brushStyle, setBrushStyle] = useState<BrushStyle>('normal');
  const [color, setColor] = useState('#000000');
  const [lineWidth, setLineWidth] = useState(5);
  const [opacity, setOpacity] = useState(1); // Brush opacity/transparency (1 = opaque, 0 = transparent)
  const [currentPath, setCurrentPath] = useState<{ x: number; y: number }[]>([]);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [actions, setActions] = useState<DrawingAction[]>([]);
  const [redoStack, setRedoStack] = useState<DrawingAction[]>([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const colors = [
    '#000000', '#ffffff', '#ff0000', '#00ff00', '#0000ff', 
    '#ffff00', '#00ffff', '#ff00ff', '#ff9900', '#9900ff',
    '#009900', '#990000', '#999999', '#555555', '#99ccff'
  ];

  const brushStyles: { id: BrushStyle; icon: React.ReactNode; name: string }[] = [
    { id: 'normal', icon: <Pencil className="h-4 w-4" />, name: t('brushStyles.normal') },
    { id: 'rough', icon: <Edit3 className="h-4 w-4" />, name: t('brushStyles.rough') },
    { id: 'sketch', icon: <Feather className="h-4 w-4" />, name: t('brushStyles.sketch') },
    { id: 'calligraphy', icon: <Brush className="h-4 w-4" />, name: t('brushStyles.calligraphy') },
    { id: 'splatter', icon: <Droplet className="h-4 w-4" />, name: t('brushStyles.splatter') },
    { id: 'dotted', icon: <div className="h-4 w-4 flex items-center justify-center">•••</div>, name: t('brushStyles.dotted') },
    { id: 'dashed', icon: <div className="h-4 w-4 flex items-center justify-center">---</div>, name: t('brushStyles.dashed') }
  ];

  // Calculate canvas size based on screen dimensions
  useEffect(() => {
    const updateCanvasSize = () => {
      if (modalRef.current) {
        const modalWidth = modalRef.current.clientWidth;
        const modalHeight = modalRef.current.clientHeight;
        
        // Calculate available space for canvas
        const availableWidth = Math.max(300, modalWidth - 40); // 40px for padding
        const availableHeight = Math.max(300, window.innerHeight * 0.4); // 40% of viewport height
        
        // Maintain aspect ratio (4:3)
        const aspectRatio = 4/3;
        let width, height;
        
        if (availableWidth / aspectRatio <= availableHeight) {
          // Width is the limiting factor
          width = availableWidth;
          height = availableWidth / aspectRatio;
        } else {
          // Height is the limiting factor
          height = availableHeight;
          width = height * aspectRatio;
        }
        
        setCanvasSize({
          width: Math.floor(width),
          height: Math.floor(height)
        });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set canvas size
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    const context = canvas.getContext('2d');
    if (!context) return;

    // Set up context
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    contextRef.current = context;

    // Fill canvas with white background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Redraw all actions
    redrawCanvas();
  }, [canvasSize]);

  useEffect(() => {
    if (!contextRef.current) return;
    contextRef.current.strokeStyle = color;
    contextRef.current.lineWidth = lineWidth;
  }, [color, lineWidth]);

  // Update color when RGB values change
  useEffect(() => {
    setColor(`rgb(${red}, ${green}, ${blue})`);
  }, [red, green, blue]);

  // Update RGB values when color changes
  useEffect(() => {
    if (color.startsWith('#')) {
      // Convert hex to RGB
      const hex = color.substring(1);
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      setRed(r);
      setGreen(g);
      setBlue(b);
    } else if (color.startsWith('rgb')) {
      // Parse RGB format
      const match = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
      if (match) {
        setRed(parseInt(match[1]));
        setGreen(parseInt(match[2]));
        setBlue(parseInt(match[3]));
      }
    }
  }, [color]);

  // Apply brush style to context
  const applyBrushStyle = (context: CanvasRenderingContext2D, style: BrushStyle) => {
    context.setLineDash([]);
    
    switch (style) {
      case 'normal':
        context.lineCap = 'round';
        context.lineJoin = 'round';
        break;
      case 'rough':
        context.lineCap = 'butt';
        context.lineJoin = 'bevel';
        break;
      case 'sketch':
        context.lineCap = 'round';
        context.lineJoin = 'round';
        // We'll handle the sketch effect during drawing
        break;
      case 'calligraphy':
        context.lineCap = 'butt';
        context.lineJoin = 'miter';
        break;
      case 'splatter':
        context.lineCap = 'round';
        context.lineJoin = 'round';
        // We'll handle the splatter effect during drawing
        break;
      case 'dotted':
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.setLineDash([2, 8]);
        break;
      case 'dashed':
        context.lineCap = 'butt';
        context.lineJoin = 'miter';
        context.setLineDash([10, 10]);
        break;
    }
  };

  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    if (!canvas || !context) return;

    // Clear canvas
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Redraw all actions
    actions.forEach(action => {
      context.strokeStyle = action.color;
      context.lineWidth = action.lineWidth;
      context.fillStyle = action.color;

      // Apply brush style if available
      if (action.brushStyle) {
        applyBrushStyle(context, action.brushStyle);
      } else {
        // Default to normal style
        context.setLineDash([]);
        context.lineCap = 'round';
        context.lineJoin = 'round';
      }

      // Apply opacity if available
      context.globalAlpha = action.opacity !== undefined ? action.opacity : 1;

      switch (action.type) {
        case 'path':
          if (!action.points || action.points.length < 2) return;
          
          if (action.brushStyle === 'sketch') {
            // Sketch effect - multiple slightly offset lines
            for (let i = 0; i < 3; i++) {
              context.beginPath();
              context.moveTo(
                action.points[0].x + (Math.random() * 2 - 1),
                action.points[0].y + (Math.random() * 2 - 1)
              );
              
              for (let j = 1; j < action.points.length; j++) {
                context.lineTo(
                  action.points[j].x + (Math.random() * 2 - 1),
                  action.points[j].y + (Math.random() * 2 - 1)
                );
              }
              context.stroke();
            }
          } else if (action.brushStyle === 'splatter') {
            // Draw the main line
            context.beginPath();
            context.moveTo(action.points[0].x, action.points[0].y);
            
            for (let i = 1; i < action.points.length; i++) {
              context.lineTo(action.points[i].x, action.points[i].y);
              
              // Add splatter dots around the line
              if (i % 3 === 0) {
                for (let j = 0; j < 5; j++) {
                  const radius = Math.random() * action.lineWidth / 2;
                  const offsetX = (Math.random() * 20 - 10);
                  const offsetY = (Math.random() * 20 - 10);
                  
                  context.beginPath();
                  context.arc(
                    action.points[i].x + offsetX,
                    action.points[i].y + offsetY,
                    radius,
                    0,
                    Math.PI * 2
                  );
                  context.fill();
                }
              }
            }
            context.stroke();
          } else if (action.brushStyle === 'calligraphy') {
            // Calligraphy effect - varying line width based on direction
            context.beginPath();
            context.moveTo(action.points[0].x, action.points[0].y);
            
            for (let i = 1; i < action.points.length; i++) {
              const prevPoint = action.points[i-1];
              const currentPoint = action.points[i];
              
              // Calculate angle of line
              const angle = Math.atan2(
                currentPoint.y - prevPoint.y,
                currentPoint.x - prevPoint.x
              );
              
              // Vary line width based on angle
              const widthVariation = Math.abs(Math.sin(angle * 2)) * action.lineWidth * 2;
              context.lineWidth = Math.max(1, widthVariation);
              
              context.lineTo(currentPoint.x, currentPoint.y);
              context.stroke();
              context.beginPath();
              context.moveTo(currentPoint.x, currentPoint.y);
            }
          } else {
            // Normal, dotted, dashed, rough styles
            context.beginPath();
            context.moveTo(action.points[0].x, action.points[0].y);
            for (let i = 1; i < action.points.length; i++) {
              context.lineTo(action.points[i].x, action.points[i].y);
            }
            context.stroke();
          }
          break;
        case 'rectangle':
          if (!action.start || !action.end) return;
          context.beginPath();
          context.rect(
            action.start.x,
            action.start.y,
            action.end.x - action.start.x,
            action.end.y - action.start.y
          );
          context.stroke();
          break;
        case 'circle':
          if (!action.start || !action.end) return;
          const radius = Math.sqrt(
            Math.pow(action.end.x - action.start.x, 2) +
            Math.pow(action.end.y - action.start.y, 2)
          );
          context.beginPath();
          context.arc(action.start.x, action.start.y, radius, 0, 2 * Math.PI);
          context.stroke();
          break;
      }
    });

    // Reset opacity
    context.globalAlpha = 1;
  };

  const getCanvasCoordinates = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): { x: number, y: number } => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();
    let clientX, clientY;

    if ('touches' in e) {
      // Touch event
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      // Mouse event
      clientX = e.clientX;
      clientY = e.clientY;
    }

    // Calculate the scale factor between the canvas's CSS size and its actual size
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    // Calculate coordinates with proper scaling
    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;

    return { x, y };
  };

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    e.preventDefault();

    const { x, y } = getCanvasCoordinates(e);

    if (tool === 'eyedropper') {
      pickColor(x, y);
      return;
    }

    setIsDrawing(true);
    setStartPoint({ x, y });

    if (tool === 'pencil' || tool === 'eraser') {
      setCurrentPath([{ x, y }]);
    }
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!contextRef.current || !canvasRef.current) return;

    e.preventDefault();

    if (!isDrawing) return;

    const { x, y } = getCanvasCoordinates(e);

    if (tool === 'pencil' || tool === 'eraser') {
      setCurrentPath(prev => [...prev, { x, y }]);
      
      // Draw current stroke
      const context = contextRef.current;
      
      context.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
      
      // Apply brush style
      applyBrushStyle(context, brushStyle);
      
      // Apply opacity
      context.globalAlpha = opacity;
      
      if (brushStyle === 'sketch') {
        // Sketch effect - multiple slightly offset lines
        for (let i = 0; i < 3; i++) {
          context.beginPath();
          
          const points = [...currentPath, { x, y }];
          if (points.length < 2) break;
          
          context.moveTo(
            points[points.length - 2].x + (Math.random() * 2 - 1),
            points[points.length - 2].y + (Math.random() * 2 - 1)
          );
          
          context.lineTo(
            x + (Math.random() * 2 - 1),
            y + (Math.random() * 2 - 1)
          );
          
          context.stroke();
        }
      } else if (brushStyle === 'splatter') {
        // Draw the main line
        context.beginPath();
        
        const points = [...currentPath, { x, y }];
        if (points.length < 2) return;
        
        context.moveTo(points[points.length - 2].x, points[points.length - 2].y);
        context.lineTo(x, y);
        context.stroke();
        
        // Add splatter dots
        if (Math.random() > 0.7) {
          for (let i = 0; i < 3; i++) {
            const radius = Math.random() * lineWidth / 2;
            const offsetX = (Math.random() * 20 - 10);
            const offsetY = (Math.random() * 20 - 10);
            
            context.beginPath();
            context.arc(
              x + offsetX,
              y + offsetY,
              radius,
              0,
              Math.PI * 2
            );
            context.fill();
          }
        }
      } else if (brushStyle === 'calligraphy') {
        // Calligraphy effect
        context.beginPath();
        
        const points = [...currentPath, { x, y }];
        if (points.length < 2) return;
        
        const prevPoint = points[points.length - 2];
        
        // Calculate angle of line
        const angle = Math.atan2(
          y - prevPoint.y,
          x - prevPoint.x
        );
        
        // Vary line width based on angle
        const widthVariation = Math.abs(Math.sin(angle * 2)) * lineWidth * 2;
        context.lineWidth = Math.max(1, widthVariation);
        
        context.moveTo(prevPoint.x, prevPoint.y);
        context.lineTo(x, y);
        context.stroke();
      } else {
        // Normal, dotted, dashed, rough styles
        context.beginPath();
        
        const points = [...currentPath, { x, y }];
        if (points.length < 2) return;
        
        context.moveTo(points[points.length - 2].x, points[points.length - 2].y);
        context.lineTo(x, y);
        context.stroke();
      }
      
      // Reset opacity
      context.globalAlpha = 1;
    } else if ((tool === 'rectangle' || tool === 'circle') && startPoint) {
      // Redraw canvas to show current shape
      redrawCanvas();
      
      const context = contextRef.current;
      
      context.strokeStyle = color;
      
      // Apply opacity
      context.globalAlpha = opacity;
      
      if (tool === 'rectangle') {
        context.beginPath();
        context.rect(
          startPoint.x,
          startPoint.y,
          x - startPoint.x,
          y - startPoint.y
        );
        context.stroke();
      } else if (tool === 'circle') {
        const radius = Math.sqrt(
          Math.pow(x - startPoint.x, 2) +
          Math.pow(y - startPoint.y, 2)
        );
        context.beginPath();
        context.arc(startPoint.x, startPoint.y, radius, 0, 2 * Math.PI);
        context.stroke();
      }
      
      // Reset opacity
      context.globalAlpha = 1;
    }
  };

  const endDrawing = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !startPoint) return;

    e.preventDefault();

    const { x, y } = getCanvasCoordinates(e);

    let newAction: DrawingAction | null = null;

    if (tool === 'pencil' || tool === 'eraser') {
      newAction = {
        type: 'path',
        points: [...currentPath, { x, y }],
        color: tool === 'eraser' ? '#ffffff' : color,
        lineWidth,
        brushStyle: tool === 'eraser' ? 'normal' : brushStyle,
        opacity: tool === 'eraser' ? 1 : opacity
      };
    } else if (tool === 'rectangle') {
      newAction = {
        type: 'rectangle',
        start: startPoint,
        end: { x, y },
        color,
        lineWidth,
        opacity
      };
    } else if (tool === 'circle') {
      newAction = {
        type: 'circle',
        start: startPoint,
        end: { x, y },
        color,
        lineWidth,
        opacity
      };
    }

    if (newAction) {
      setActions([...actions, newAction]);
      setRedoStack([]);
    }

    setIsDrawing(false);
    setCurrentPath([]);
    setStartPoint(null);
  };

  const pickColor = (x: number, y: number) => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    if (!canvas || !context) return;

    // Get the pixel data at the clicked position
    const pixelData = context.getImageData(x, y, 1, 1).data;

    // Convert RGB to hex
    const r = pixelData[0];
    const g = pixelData[1];
    const b = pixelData[2];
    
    setRed(r);
    setGreen(g);
    setBlue(b);
    setColor(`rgb(${r}, ${g}, ${b})`);
    
    // Switch back to pencil tool after picking a color
    setTool('pencil');
  };

  const handleUndo = () => {
    if (actions.length === 0) return;

    const newActions = [...actions];
    const lastAction = newActions.pop();
    if (lastAction) {
      setRedoStack([...redoStack, lastAction]);
    }
    setActions(newActions);

    redrawCanvas();
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;

    const newRedoStack = [...redoStack];
    const actionToRedo = newRedoStack.pop();
    if (actionToRedo) {
      setActions([...actions, actionToRedo]);
    }
    setRedoStack(newRedoStack);

    redrawCanvas();
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dataUrl = canvas.toDataURL('image/png');
    onSave(dataUrl);
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `drawing-${Date.now()}.png`;
    link.href = dataUrl;
    link.click();
  };

  const handleColorChange = (newColor: string) => {
    setColor(newColor);
    setShowColorPicker(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-y-auto">
      <div 
        ref={modalRef}
        className={`w-full max-w-2xl h-[90vh] rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl overflow-hidden flex flex-col`}
      >
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          <div className="flex flex-col space-y-4">
            {/* Tools */}
            <div className="flex flex-wrap justify-between items-center gap-2">
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => setTool('pencil')}
                  className={`p-2 rounded-lg ${tool === 'pencil' 
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('tools.pencil')}
                >
                  <Pencil className="h-5 w-5" />
                </button>
                <button
                  onClick={() => setTool('rectangle')}
                  className={`p-2 rounded-lg ${tool === 'rectangle' 
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('tools.rectangle')}
                >
                  <Square className="h-5 w-5" />
                </button>
                <button
                  onClick={() => setTool('circle')}
                  className={`p-2 rounded-lg ${tool === 'circle' 
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('tools.circle')}
                >
                  <Circle className="h-5 w-5" />
                </button>
                <button
                  onClick={() => setTool('eraser')}
                  className={`p-2 rounded-lg ${tool === 'eraser' 
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('tools.eraser')}
                >
                  <Eraser className="h-5 w-5" />
                </button>
                <button
                  onClick={() => setTool('eyedropper')}
                  className={`p-2 rounded-lg ${tool === 'eyedropper' 
                    ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('tools.eyedropper')}
                >
                  <Pipette className="h-5 w-5" />
                </button>
              </div>

              <div className="flex flex-wrap gap-2">
                <button
                  onClick={handleUndo}
                  disabled={actions.length === 0}
                  className={`p-2 rounded-lg ${
                    isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                  } disabled:opacity-50`}
                  title={t('actions.undo')}
                >
                  <Undo className="h-5 w-5" />
                </button>
                <button
                  onClick={handleRedo}
                  disabled={redoStack.length === 0}
                  className={`p-2 rounded-lg ${
                    isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                  } disabled:opacity-50`}
                  title={t('actions.redo')}
                >
                  <Redo className="h-5 w-5" />
                </button>
                <button
                  onClick={handleDownload}
                  className={`p-2 rounded-lg ${
                    isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                  }`}
                  title={t('actions.download')}
                >
                  <Download className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Brush Styles */}
            <div>
              <p className={`text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('brushStyle')}
              </p>
              <div className="flex flex-wrap gap-2">
                {brushStyles.map((style) => (
                  <button
                    key={style.id}
                    onClick={() => setBrushStyle(style.id)}
                    className={`p-2 rounded-lg flex items-center space-x-1 ${
                      brushStyle === style.id
                        ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                        : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                    }`}
                    title={style.name}
                  >
                    {style.icon}
                    <span className="text-xs">{style.name}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Transparency Slider */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <p className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('transparency')}: {Math.round((1 - opacity) * 100)}%
                </p>
                <div className="flex items-center space-x-2">
                  <Layers className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                  <div 
                    className="w-6 h-6 rounded-full border-2 border-gray-300 relative overflow-hidden"
                    style={{ backgroundColor: color }}
                  >
                    <div 
                      className="absolute inset-0 bg-white"
                      style={{ opacity: 1 - opacity }}
                    />
                  </div>
                </div>
              </div>
              <input
                type="range"
                min="0"
                max="100"
                value={Math.round((1 - opacity) * 100)}
                onChange={(e) => setOpacity(1 - parseInt(e.target.value) / 100)}
                className="w-full"
              />
            </div>

            {/* Color Picker */}
            <div>
              <div className="flex justify-between items-center mb-2">
                <p className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('colors')}
                </p>
                <button
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  className={`p-2 rounded-lg ${
                    showColorPicker
                      ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                      : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700')
                  }`}
                  title={t('colorPicker')}
                >
                  <Palette className="h-5 w-5" />
                </button>
              </div>
              
              {showColorPicker ? (
                <div className={`p-4 rounded-lg mb-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <div className="space-y-4">
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <label className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>R: {red}</label>
                        <div 
                          className="w-6 h-6 rounded-full" 
                          style={{ backgroundColor: `rgb(${red}, 0, 0)` }}
                        />
                      </div>
                      <input 
                        type="range" 
                        min="0" 
                        max="255" 
                        value={red} 
                        onChange={(e) => setRed(parseInt(e.target.value))}
                        className="w-full"
                      />
                    </div>
                    
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <label className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>G: {green}</label>
                        <div 
                          className="w-6 h-6 rounded-full" 
                          style={{ backgroundColor: `rgb(0, ${green}, 0)` }}
                        />
                      </div>
                      <input 
                        type="range" 
                        min="0" 
                        max="255" 
                        value={green} 
                        onChange={(e) => setGreen(parseInt(e.target.value))}
                        className="w-full"
                      />
                    </div>
                    
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <label className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>B: {blue}</label>
                        <div 
                          className="w-6 h-6 rounded-full" 
                          style={{ backgroundColor: `rgb(0, 0, ${blue})` }}
                        />
                      </div>
                      <input 
                        type="range" 
                        min="0" 
                        max="255" 
                        value={blue} 
                        onChange={(e) => setBlue(parseInt(e.target.value))}
                        className="w-full"
                      />
                    </div>
                    
                    <div className="flex justify-between items-center">
                      <label className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Preview:</label>
                      <div 
                        className="w-10 h-10 rounded-full border-2 border-gray-300" 
                        style={{ backgroundColor: color }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {colors.map((c) => (
                    <button
                      key={c}
                      onClick={() => handleColorChange(c)}
                      className={`w-8 h-8 rounded-full border-2 ${
                        color === c 
                          ? 'border-blue-500' 
                          : isDarkMode ? 'border-gray-700' : 'border-gray-200'
                      }`}
                      style={{ backgroundColor: c }}
                      aria-label={`Color ${c}`}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* Line Width */}
            <div>
              <p className={`text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('lineWidth')}: {lineWidth}
              </p>
              <input
                type="range"
                min="1"
                max="20"
                value={lineWidth}
                onChange={(e) => setLineWidth(parseInt(e.target.value))}
                className="w-full"
              />
            </div>

            {/* Canvas */}
            <div 
              ref={canvasContainerRef}
              className={`border ${isDarkMode ? 'border-gray-700' : 'border-gray-300'} rounded-lg overflow-hidden`}
              style={{ 
                width: '100%',
                height: 'auto',
                position: 'relative',
                aspectRatio: `${canvasSize.width}/${canvasSize.height}`
              }}
            >
              <canvas
                ref={canvasRef}
                width={canvasSize.width}
                height={canvasSize.height}
                className="touch-none w-full h-full"
                style={{ 
                  cursor: tool === 'eyedropper' ? 'crosshair' : 'default'
                }}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={endDrawing}
                onMouseLeave={endDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={endDrawing}
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-3 pt-4">
              <button
                onClick={onClose}
                className={`px-4 py-2 ${
                  isDarkMode 
                    ? 'text-gray-300 hover:bg-gray-700' 
                    : 'text-gray-700 hover:bg-gray-100'
                } rounded-lg`}
              >
                {t('actions.cancel')}
              </button>
              <button
                onClick={handleSave}
                className={`px-4 py-2 text-white rounded-lg flex items-center space-x-2 ${
                  isDarkMode 
                    ? 'bg-blue-600 hover:bg-blue-700' 
                    : 'bg-blue-500 hover:bg-blue-600'
                }`}
              >
                <Check className="h-5 w-5" />
                <span>{t('actions.save')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}