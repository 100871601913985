import { useState, useEffect, useRef } from 'react';
import { Pencil, Loader2, Heart, Bomb, Flame, Trash2, Clock } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { DrawingPanel } from '../components/DrawingPanel';
import { collection, addDoc, serverTimestamp, query, where, orderBy, limit, updateDoc, arrayUnion, arrayRemove, getDocs, doc, startAfter, deleteDoc } from 'firebase/firestore';
import { ref, uploadString, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosting Draw',
    createDrawing: 'Criar',
    loading: 'Carregando desenhos...',
    noDrawings: 'Nenhum desenho encontrado',
    yourDrawings: 'Seus Desenhos',
    recentDrawings: 'Desenhos Recentes',
    expiresIn: 'Expira em',
    hours: 'horas',
    loadMore: '+ Desenhos',
    loadingMore: 'Carregando mais...',
    deleteDrawing: 'Excluir desenho',
    deleteConfirm: 'Tem certeza que deseja excluir este desenho?',
    adminDelete: 'Admin: Excluir desenho',
    cooldownMessage: 'Você poderá criar outro desenho em:',
    errors: {
      uploadFailed: 'Falha ao enviar desenho',
      cooldownError: 'Você só pode criar um desenho a cada hora',
      deleteFailed: 'Falha ao excluir desenho'
    },
    success: {
      drawingCreated: 'Desenho criado com sucesso!',
      liked: 'Você curtiu este desenho!',
      unliked: 'Você removeu sua curtida!',
      deleted: 'Desenho excluído com sucesso!',
      adminDeleted: 'Desenho excluído pelo admin com sucesso!'
    }
  },
  'en': {
    title: 'Poosting Draw',
    createDrawing: 'Create Drawing',
    loading: 'Loading drawings...',
    noDrawings: 'No drawings found',
    yourDrawings: 'Your Drawings',
    recentDrawings: 'Recent Drawings',
    expiresIn: 'Expires in',
    hours: 'hours',
    loadMore: '+ Drawings',
    loadingMore: 'Loading more...',
    deleteDrawing: 'Delete drawing',
    deleteConfirm: 'Are you sure you want to delete this drawing?',
    adminDelete: 'Admin: Delete drawing',
    cooldownMessage: 'You can create another drawing in:',
    errors: {
      uploadFailed: 'Failed to upload drawing',
      cooldownError: 'You can only create one drawing per hour',
      deleteFailed: 'Failed to delete drawing'
    },
    success: {
      drawingCreated: 'Drawing created successfully!',
      liked: 'You liked this drawing!',
      unliked: 'You removed your like!',
      deleted: 'Drawing deleted successfully!',
      adminDeleted: 'Drawing deleted by admin successfully!'
    }
  }
};

interface Drawing {
  id: string;
  imageUrl: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  likes?: string[];
}

export function Drawing() {
  const [showDrawingPanel, setShowDrawingPanel] = useState(false);
  const [drawings, setDrawings] = useState<Drawing[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState<number | null>(null);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  // Check if user is admin
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;
    
    const fetchDrawings = async () => {
      try {
        setLoading(true);
        
        // Calculate timestamp for 24 hours ago
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
        
        // Fetch recent drawings - limited to 20 most recent
        const drawingsRef = collection(db, 'drawings');
        const recentQuery = query(
          drawingsRef,
          where('timestamp', '>=', twentyFourHoursAgo),
          orderBy('timestamp', 'desc'),
          limit(20)
        );
        
        const snapshot = await getDocs(recentQuery);
        const drawingsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          likes: doc.data().likes || []
        })) as Drawing[];
        
        setDrawings(drawingsData);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === 20);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching drawings:', error);
        setLoading(false);
      }
    };
    
    fetchDrawings();
    
    // Check cooldown timer
    checkDrawingCooldown();
    
    // Set up interval to update cooldown timer
    const interval = setInterval(checkDrawingCooldown, 1000);
    return () => clearInterval(interval);
  }, [user]);

  const loadMoreDrawings = async () => {
    if (!user || !lastVisible || loadingMore) return;
    
    try {
      setLoadingMore(true);
      
      // Calculate timestamp for 24 hours ago
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      
      // Fetch more drawings
      const drawingsRef = collection(db, 'drawings');
      const moreQuery = query(
        drawingsRef,
        where('timestamp', '>=', twentyFourHoursAgo),
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(20)
      );
      
      const snapshot = await getDocs(moreQuery);
      
      const moreDrawingsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date(),
        likes: doc.data().likes || []
      })) as Drawing[];
      
      setDrawings(prev => [...prev, ...moreDrawingsData]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 20);
      setLoadingMore(false);
    } catch (error) {
      console.error('Error fetching more drawings:', error);
      setLoadingMore(false);
    }
  };

  const checkDrawingCooldown = () => {
    const lastDrawingTime = localStorage.getItem('lastDrawingTime');
    if (!lastDrawingTime) {
      setCooldownRemaining(null);
      return;
    }
    
    const now = Date.now();
    const lastTime = parseInt(lastDrawingTime);
    const hourInMs = 60 * 60 * 1000; // 1 hour in milliseconds
    const timeLeft = lastTime + hourInMs - now;
    
    if (timeLeft <= 0) {
      setCooldownRemaining(null);
      return;
    }
    
    setCooldownRemaining(timeLeft);
  };

  const formatCooldownTime = (ms: number): string => {
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDrawingSave = async (imageData: string) => {
    if (!user) return;
    
    // Check if user is on cooldown
    if (cooldownRemaining !== null && cooldownRemaining > 0) {
      toast.error(t('errors.cooldownError'));
      return;
    }
    
    try {
      // Upload drawing to storage
      const timestamp = Date.now();
      const storageRef = ref(storage, `drawings/${user.uid}/${timestamp}.png`);
      
      // Remove data URL prefix
      const base64Data = imageData.split(',')[1];
      
      await uploadString(storageRef, base64Data, 'base64', {
        contentType: 'image/png'
      });
      
      const imageUrl = await getDownloadURL(storageRef);
      
      // Add drawing to Firestore
      const drawingDoc = await addDoc(collection(db, 'drawings'), {
        imageUrl,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        likes: []
      });
      
      // Set cooldown
      localStorage.setItem('lastDrawingTime', Date.now().toString());
      checkDrawingCooldown();
      
      // Add the new drawing to the top of the list
      const newDrawing = {
        id: drawingDoc.id,
        imageUrl,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: new Date(),
        likes: []
      };
      
      setDrawings(prev => [newDrawing, ...prev]);
      
      toast.success(t('success.drawingCreated'));
      setShowDrawingPanel(false);
    } catch (error) {
      console.error('Error saving drawing:', error);
      toast.error(t('errors.uploadFailed'));
    }
  };

  const handleLikeDrawing = async (drawing: Drawing) => {
    if (!user) return;
    
    try {
      const drawingRef = doc(db, 'drawings', drawing.id);
      const isLiked = drawing.likes?.includes(user.uid) || false;
      
      if (isLiked) {
        await updateDoc(drawingRef, {
          likes: arrayRemove(user.uid)
        });
        
        // Update local state
        setDrawings(prev => 
          prev.map(d => 
            d.id === drawing.id 
              ? { ...d, likes: d.likes?.filter(id => id !== user.uid) || [] } 
              : d
          )
        );
        
        toast.success(t('success.unliked'));
      } else {
        await updateDoc(drawingRef, {
          likes: arrayUnion(user.uid)
        });
        
        // Update local state
        setDrawings(prev => 
          prev.map(d => 
            d.id === drawing.id 
              ? { ...d, likes: [...(d.likes || []), user.uid] } 
              : d
          )
        );
        
        toast.success(t('success.liked'));
      }
    } catch (error) {
      console.error('Error liking drawing:', error);
      toast.error('Failed to update like');
    }
  };

  const handleDeleteDrawing = async (drawingId: string, authorId: string) => {
    // Only allow deletion if user is the author or admin
    if (!user || (user.uid !== authorId && !isAdmin)) return;
    
    const confirmDelete = window.confirm(t('deleteConfirm'));
    if (!confirmDelete) return;
    
    try {
      setDeleting(drawingId);
      
      // Get the drawing document to get the image URL
      const drawingRef = doc(db, 'drawings', drawingId);
      const drawingDoc = await getDocs(query(collection(db, 'drawings'), where('__name__', '==', drawingId)));
      
      if (!drawingDoc.empty) {
        const drawingData = drawingDoc.docs[0].data();
        const imageUrl = drawingData.imageUrl;
        
        // Delete the image from storage if it exists
        if (imageUrl) {
          try {
            // Extract the path from the URL
            const storageUrl = imageUrl.split('?')[0]; // Remove query parameters
            const storagePath = decodeURIComponent(storageUrl.split('/o/')[1]); // Get the path after /o/
            const storageRef = ref(storage, storagePath);
            await deleteObject(storageRef);
          } catch (error) {
            console.error('Error deleting image from storage:', error);
            // Continue with document deletion even if image deletion fails
          }
        }
        
        // Delete the drawing document
        await deleteDoc(drawingRef);
        
        // Update local state
        setDrawings(prev => prev.filter(d => d.id !== drawingId));
        
        // Show success message
        if (isAdmin && user.uid !== authorId) {
          toast.success(t('success.adminDeleted'));
        } else {
          toast.success(t('success.deleted'));
        }
      }
    } catch (error) {
      console.error('Error deleting drawing:', error);
      toast.error(t('errors.deleteFailed'));
    } finally {
      setDeleting(null);
    }
  };

  const calculateTimeLeft = (timestamp: Date) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const expiresAt = new Date(createdAt.getTime() + 24 * 60 * 60 * 1000); // 24 hours
    
    const timeLeft = expiresAt.getTime() - now.getTime();
    const hoursLeft = Math.max(0, Math.floor(timeLeft / (1000 * 60 * 60)));
    const minutesLeft = Math.max(0, Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)));
    const secondsLeft = Math.max(0, Math.floor((timeLeft % (1000 * 60)) / 1000));
    
    return {
      hours: hoursLeft,
      minutes: minutesLeft,
      seconds: secondsLeft,
      timeLeftMs: timeLeft,
      progress: Math.max(0, Math.min(100, (timeLeft / (24 * 60 * 60 * 1000)) * 100))
    };
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Pencil className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
          <button
            onClick={() => {
              if (cooldownRemaining === null) {
                setShowDrawingPanel(true);
              } else {
                toast.error(t('errors.cooldownError'));
              }
            }}
            className={`px-4 py-2 rounded-full ${
              isDarkMode 
                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                : 'bg-blue-500 text-white hover:bg-blue-600'
            } ${cooldownRemaining !== null ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {t('createDrawing')}
          </button>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen p-4`}>
        {/* Cooldown Timer */}
        {cooldownRemaining !== null && (
          <div className={`mb-4 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center space-x-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {t('cooldownMessage')}
                </span>
              </div>
              <div className={`font-bold text-lg ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`}>
                {formatCooldownTime(cooldownRemaining)}
              </div>
            </div>
            <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-500 transition-all duration-1000"
                style={{ width: `${(cooldownRemaining / (60 * 60 * 1000)) * 100}%` }}
              />
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center py-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : (
          <div className="space-y-8">
            {/* Recent Drawings - Single Column */}
            <div>
              <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {t('recentDrawings')}
              </h2>
              {drawings.length > 0 ? (
                <div className="space-y-6">
                  {drawings.map((drawing) => {
                    const timeLeft = calculateTimeLeft(drawing.timestamp);
                    const isAuthor = user?.uid === drawing.authorId;
                    
                    return (
                      <div 
                        key={drawing.id}
                        className={`rounded-lg overflow-hidden ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} shadow-md`}
                      >
                        <div className="p-4 border-b border-gray-600">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <Link to={`/profile/${drawing.authorUsername}`}>
                                <img
                                  src={drawing.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${drawing.authorId}`}
                                  alt={drawing.authorName}
                                  className="h-10 w-10 rounded-full"
                                />
                              </Link>
                              <div>
                                <Link 
                                  to={`/profile/${drawing.authorUsername}`}
                                  className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                  {drawing.authorName}
                                </Link>
                                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                                  @{drawing.authorUsername}
                                </p>
                              </div>
                            </div>
                            
                            {/* Delete button for author or admin */}
                            {(isAuthor || isAdmin) && (
                              <button
                                onClick={() => handleDeleteDrawing(drawing.id, drawing.authorId)}
                                disabled={deleting === drawing.id}
                                className={`p-2 ${
                                  isDarkMode 
                                    ? 'text-red-400 hover:bg-red-900/30' 
                                    : 'text-red-500 hover:bg-red-50'
                                } rounded-full transition-colors`}
                                title={isAdmin && !isAuthor ? t('adminDelete') : t('deleteDrawing')}
                              >
                                {deleting === drawing.id ? (
                                  <Loader2 className="h-5 w-5 animate-spin" />
                                ) : (
                                  <Trash2 className="h-5 w-5" />
                                )}
                              </button>
                            )}
                          </div>
                          
                          <div className="flex items-center justify-between mt-2">
                            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              {formatDistanceToNow(drawing.timestamp, {
                                addSuffix: true,
                                locale: language === 'pt-BR' ? ptBR : undefined
                              })}
                            </p>
                          </div>
                        </div>
                        
                        <div className="w-full">
                          <img 
                            src={drawing.imageUrl} 
                            alt={`Drawing by ${drawing.authorName}`}
                            className="w-full object-contain"
                            loading="lazy"
                          />
                        </div>
                        
                        {/* Post Lifetime Timeline */}
                        <div className="mt-4 mb-1 px-4">
                          <div className="relative flex items-center">
                            <div className="flex-1 relative mx-2">
                              <div className="h-1 bg-gray-200 rounded-full overflow-hidden">
                                <div 
                                  className="h-full bg-gradient-to-r from-orange-300 via-orange-500 to-red-600 transition-all duration-1000"
                                  style={{ width: `${timeLeft.progress}%` }}
                                />
                              </div>

                              <div 
                                className="absolute top-1/2 -translate-y-1/2 transition-all duration-1000"
                                style={{ left: `${timeLeft.progress}%`, transform: `translateX(-50%) translateY(-50%)` }}
                              >
                                <div className={`relative flex items-center ${
                                  timeLeft.timeLeftMs === 0 
                                    ? 'animate-bounce' 
                                    : 'animate-[wiggle_1s_ease-in-out_infinite]'
                                }`}>
                                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                                    <Flame 
                                      className={`h-4 w-4 text-orange-500 ${
                                        timeLeft.progress <= 50 ? 'animate-[flame_0.5s_ease-in-out_infinite]' : ''
                                      }`}
                                    />
                                  </div>

                                  <Bomb 
                                    className={`h-5 w-5 ${isDarkMode ? 'text-white' : 'text-black'} transform transition-transform ${
                                      timeLeft.progress <= 25 ? 'animate-[shake_0.5s_ease-in-out_infinite]' : ''
                                    }`}
                                  />

                                  {timeLeft.progress <= 25 && (
                                    <div className="absolute -top-1 -right-1 h-2 w-2 bg-red-500 rounded-full animate-ping" />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className={`w-20 text-right text-xs font-medium flex items-center justify-end ${
                              timeLeft.progress <= 25
                                ? 'text-red-500'
                                : isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                              <div className="bg-opacity-75 rounded">
                                {timeLeft.hours}:{timeLeft.minutes.toString().padStart(2, '0')}:{timeLeft.seconds.toString().padStart(2, '0')}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="p-4">
                          <div className="flex items-center space-x-4">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLikeDrawing(drawing);
                              }}
                              className={`flex items-center space-x-1 ${
                                drawing.likes?.includes(user?.uid || '') 
                                  ? 'text-red-500' 
                                  : isDarkMode 
                                    ? 'text-gray-400 hover:text-red-400' 
                                    : 'text-gray-500 hover:text-red-500'
                              }`}
                            >
                              <Heart className={drawing.likes?.includes(user?.uid || '') ? 'fill-current' : ''} size={20} />
                              <span>{drawing.likes?.length || 0}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  
                  {hasMore && (
                    <div className="flex justify-center mt-6">
                      <button
                        onClick={loadMoreDrawings}
                        disabled={loadingMore}
                        className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                          isDarkMode 
                            ? 'bg-gray-700 text-white hover:bg-gray-600' 
                            : 'bg-black text-white hover:bg-gray-800'
                        }`}
                      >
                        {loadingMore ? (
                          <>
                            <Loader2 className="h-4 w-4 animate-spin" />
                            <span>{t('loadingMore')}</span>
                          </>
                        ) : (
                          <span>{t('loadMore')}</span>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center py-8">
                  <Pencil className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
                  <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                    {t('noDrawings')}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </main>

      {showDrawingPanel && (
        <DrawingPanel
          onSave={handleDrawingSave}
          onClose={() => setShowDrawingPanel(false)}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}