import { useEffect } from 'react';
import { useLocalization } from '../contexts/LocalizationContext';
import { DrawingsCarousel } from './DrawingsCarousel';

interface RssCarouselAfterPostsProps {
  postCount: number;
}

export function RssCarouselAfterPosts({ postCount }: RssCarouselAfterPostsProps) {
  const { country } = useLocalization();
  const isBrazil = country === 'BR';
  
  // Only show carousel after every 20 posts
  if (postCount === 0 || postCount % 20 !== 0) {
    return null;
  }

  useEffect(() => {
    // Load RSS widget script
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/carousel.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <div className="my-4 bg-white rounded-lg shadow">
        {isBrazil ? (
          <rssapp-carousel id="_boTSs5MdJSEeGWq5"></rssapp-carousel>
        ) : (
          <rssapp-carousel id="_rRC45hLVQmmuLbhE"></rssapp-carousel>
        )}
      </div>
      
      {/* Drawings Carousel */}
      <DrawingsCarousel postCount={postCount} />
    </div>
  );
}