import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Pencil, Loader2, ChevronLeft, ChevronRight, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useTheme } from '../contexts/ThemeContext';

interface Drawing {
  id: string;
  imageUrl: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  likes?: string[];
}

interface DrawingsCarouselProps {
  postCount: number;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Desenhos',
    loading: 'Carregando desenhos...',
    noDrawings: 'Nenhum desenho encontrado',
    viewAll: 'Ver Todos',
    by: 'por'
  },
  'en': {
    title: 'Draw',
    loading: 'Loading drawings...',
    noDrawings: 'No drawings found',
    viewAll: '(+)',
    by: 'by'
  }
};

// Show carousel every 20 posts
const POST_INTERVAL = 20;
const DRAWINGS_TO_FETCH = 10;

export function DrawingsCarousel({ postCount, isDarkMode = false, language = 'en' }: DrawingsCarouselProps) {
  const [drawings, setDrawings] = useState<Drawing[]>([]);
  const [loading, setLoading] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { isDarkMode: themeIsDark } = useTheme();

  const actualIsDarkMode = isDarkMode || themeIsDark;

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    if (postCount === 0 || postCount % POST_INTERVAL !== 0) {
      return;
    }

    const fetchDrawings = async () => {
      try {
        setLoading(true);
        
        // Calculate timestamp for 24 hours ago
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
        
        // Fetch recent drawings
        const drawingsRef = collection(db, 'drawings');
        const recentQuery = query(
          drawingsRef,
          where('timestamp', '>=', twentyFourHoursAgo),
          orderBy('timestamp', 'desc'),
          limit(DRAWINGS_TO_FETCH)
        );
        
        const snapshot = await getDocs(recentQuery);
        const drawingsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          likes: doc.data().likes || []
        })) as Drawing[];
        
        setDrawings(drawingsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching drawings:', error);
        setLoading(false);
      }
    };
    
    fetchDrawings();
  }, [postCount]);

  const checkScrollButtons = () => {
    if (!containerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, [drawings]);

  const scroll = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;

    const scrollAmount = 300;
    const container = containerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  if (postCount === 0 || postCount % POST_INTERVAL !== 0 || drawings.length === 0) {
    return null;
  }

  return (
    <div className={`py-4 border-b ${actualIsDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <div className="max-w-2xl mx-auto px-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <Pencil className={`h-5 w-5 ${actualIsDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h2 className={`text-lg font-semibold ${actualIsDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('title')}
            </h2>
          </div>
          <Link 
            to="/drawing"
            className={`text-sm ${
              actualIsDarkMode 
                ? 'text-blue-400 hover:text-blue-300' 
                : 'text-blue-500 hover:text-blue-600'
            }`}
          >
            {t('viewAll')}
          </Link>
        </div>

        {loading ? (
          <div className="flex justify-center py-4">
            <Loader2 className={`h-6 w-6 animate-spin ${actualIsDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : (
          <div className="relative">
            {/* Left Arrow */}
            {showLeftArrow && (
              <button
                onClick={() => scroll('left')}
                className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 rounded-full shadow-lg ${
                  actualIsDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
            )}

            {/* Scrollable Container */}
            <div 
              ref={containerRef}
              className="flex space-x-4 overflow-x-auto scrollbar-hide scroll-smooth pb-4"
              onScroll={handleScroll}
            >
              {drawings.map((drawing) => (
                <div 
                  key={drawing.id}
                  className="flex-none w-60"
                >
                  <div className={`rounded-lg overflow-hidden ${
                    actualIsDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                  } shadow-md`}>
                    <div className="aspect-square relative">
                      <img 
                        src={drawing.imageUrl} 
                        alt={`Drawing by ${drawing.authorName}`}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    </div>
                    
                    <div className="p-3">
                      <div className="flex items-center space-x-2">
                        <Link to={`/profile/${drawing.authorUsername}`}>
                          <img
                            src={drawing.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${drawing.authorId}`}
                            alt={drawing.authorName}
                            className="w-8 h-8 rounded-full"
                          />
                        </Link>
                        <div className="flex-1 min-w-0">
                          <Link 
                            to={`/profile/${drawing.authorUsername}`}
                            className={`font-medium text-sm hover:underline truncate block ${
                              actualIsDarkMode ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            @{drawing.authorUsername}
                          </Link>
                        </div>
                        <div className="flex items-center space-x-1">
                          <Heart className={`h-4 w-4 ${
                            drawing.likes?.includes(user?.uid || '') 
                              ? 'text-red-500 fill-current' 
                              : actualIsDarkMode 
                                ? 'text-gray-400' 
                                : 'text-gray-500'
                          }`} />
                          <span className={`text-xs ${
                            actualIsDarkMode ? 'text-gray-400' : 'text-gray-500'
                          }`}>
                            {drawing.likes?.length || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Right Arrow */}
            {showRightArrow && (
              <button
                onClick={() => scroll('right')}
                className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 rounded-full shadow-lg ${
                  actualIsDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}